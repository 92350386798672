import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import logo from './bigrewards-logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#e8f7fc',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: '#1976d2',
    color: '#FFFFFF',
    marginTop: theme.spacing(2),
  },
  logo: {
    width: '100%', // or any other size you want
    height: 'auto', // maintain aspect ratio
  },
}));

function LandingPage() {
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <img src={logo} alt="Logo" className={classes.logo} />
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Enjoy exclusive rewards, personalized offers, and priority perks today!
        </Typography>
        <Grid container justifyContent="center">
          <Link to="/login">
            <Button variant="contained" className={classes.button}>
              Login/ Register
            </Button>
          </Link>
        </Grid>
      </Container>
    </div>
  );
}

export default LandingPage;