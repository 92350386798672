// client/src/RegisterShop.js
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import UserContext from "./UserContext";
import { useNavigate } from 'react-router-dom';

function RegisterShop() {
    const { user, setUser } = useContext(UserContext);
    const [shopName, setShopName] = useState("");
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const register = async (e) => {
        e.preventDefault();

        const newShop = { shopName };

        try {
            const response = await axios.post("/api/shops/create", newShop, {
                headers: {
                    'X-CSRF-Token': csrfToken
                }
            });

            // setUser(null);
            setSuccess(true);



            // Delay the redirection by 5 seconds
            setTimeout(() => {
                
                axios.post('/api/users/logout')
                    .then(() => {
                        setUser(null);
                    })
                    .catch(err => console.error(err));

                navigate("/login");
            }, 3000);

        } catch (err) {

            if (err.response.data.error) {

                setErrors({ general: err.response.data.error });

            } else if (err.response.data.errors) {

                setErrors(err.response.data.errors);
            }

        }
    };

    return (
        <form onSubmit={register}>
            <input type="text" onChange={(e) => { setShopName(e.target.value); setErrors({}); }} placeholder="Shop Name" />

            {errors.shopName && <div className="error">{errors.shopName}</div>}

            {errors.general && <div className="error">{errors.general}</div>}

            {success && <div className="success">Shop Registration successful! You will be redirected to login once more.</div>}

            <button type="submit">Register Shop</button>
        </form>
    );
}

export default RegisterShop;