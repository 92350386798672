// client/src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from 'axios';

import UserContext from "./UserContext";

import Register from "./Register";
import Login from "./Login";

import Dashboard from "./Dashboard";
import ShopDashboard from "./shopDashboard";
import RegisterShop from "./registerShop";

import LandingPage from "./LandingPage";

import AuthLayout from "./ProtectedRoute";

import Loader from "./Loader";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


  useEffect(() => {

    // Check if the user is logged in by checking cookie value.
    const getUser = async () => {
      try {
        const response = await axios.get('/api/users/current', {
          headers: {
            'X-CSRF-Token': csrfToken
          }
        });
    
        if (response.data.user === null) {

          setUser(null);

        } else {

          setUser(response.data.user);

        }
      } catch (error) {

        console.error('Failed to fetch user', error);
        setUser(null);

      } finally {

        setLoading(false);

      }
    };
  
    getUser();
  }, []);

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<LandingPage />} />

          <Route element={<AuthLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/shop-dashboard" element={<ShopDashboard />} />
            <Route path="/register-shop" element={<RegisterShop />} />

          </Route>

        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;