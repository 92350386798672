import React, { useContext, useState, useEffect } from "react";
import { QrReader } from 'react-qr-reader'
import { useNavigate } from 'react-router-dom';
import UserContext from "./UserContext";

import axios from "axios";
import { Container, Typography, Button, List, ListItem, ListItemText, Grid, makeStyles, Box, AppBar, Toolbar, TextField } from '@material-ui/core';

import logo from './bigrewards-logo.png';
import { set } from "mongoose";

import MetricsChart from './MetricsChart';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#e8f7fc',
    // minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  title: {
    flexGrow: 1,
  },
  points: {
    fontFamily: "'Fredericka the Great', cursive",
    color: '#FFFFFF',
    // textShadow: '2px 2px #FF0000',
  },
  appBar: {
    backgroundColor: '#2F97C1',
  }
}));

function ShopDashboard() {
  const classes = useStyles();

  const { setUser, user } = useContext(UserContext);
  const [serverResponse, setServerResponse] = useState('');
  const [isScanning, setIsScanning] = useState(false);

  const [rewards, setRewards] = useState([]);
  // const [testrewards, testsetRewards] = useState([]);
  const [newReward, setNewReward] = useState('');

  const [newRewardName, setNewRewardName] = useState('');
  const [newRewardDescription, setNewRewardDescription] = useState('');
  const [newRewardPointsRequired, setNewRewardPointsRequired] = useState('');
  const [shopPublicID, setShopPublicID] = useState('');
  const [shopPointsValue, setShopPointsValue] = useState();
  const [userPointsOnSignUp, setuserPointsOnSignUp] = useState();

  const [metrics, setMetrics] = useState(null);

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();


  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  useEffect(() => {

    // Set the background color when the component mounts
    document.body.style.backgroundColor = '#e8f7fc';

    if (user) {

      if (user.shopOwner == false) {
        navigate('/dashboard');
      }

    } else {

      navigate('/login');

    }

    // Fetch rewards when the component mounts
    axios.get(`/api/shops/rewards`)
      .then(response => {
        setRewards(response.data.shopRewards)
        setShopPublicID(response.data.publicShopID)
        setShopPointsValue(response.data.pointsValue)
        setuserPointsOnSignUp(response.data.userPointsOnSignUp)
      })
      .catch(error => console.error('Error:', error));


    return () => {
      document.body.style.backgroundColor = null;
    };

  }, []);

  const addReward = async (event) => {
    // Prevent the form from being submitted
    event.preventDefault();

    const pointsRequired = Number(newRewardPointsRequired);

    if (!Number.isInteger(pointsRequired)) {
      setErrors({ newRewardPointsRequired: 'Points Required must be a numeric value.' });
      return;
    }

    const newReward = {
      name: newRewardName,
      description: newRewardDescription,
      pointsRequired: pointsRequired
    };

    try {

      const response = await axios.post("/api/shops/new-reward", { reward: newReward }, {
        headers: {
          'X-CSRF-Token': csrfToken
        }
      });

      setRewards(response.data);
      setNewRewardName('');
      setNewRewardDescription('');
      setNewRewardPointsRequired('');


    } catch (err) {

      if (err.response.data.error) {

        setErrors({ general: err.response.data.error });

      } else if (err.response.data.errors) {

        setErrors(err.response.data.errors);
      }

    }
  };


  const editShopPointsValue = async (event) => {

    let newValue = window.prompt("Please enter the new value:");

    if (newValue === null || newValue === "" || isNaN(newValue)) {

      console.log("No value entered.");
      return

    } else {

      try {

        const response = await axios.post("/api/shops/edit-shop-points-value", { newValue: newValue }, {
          headers: {
            'X-CSRF-Token': csrfToken
          }
        });

        setShopPointsValue(newValue);

      } catch (err) {

        console.log(err)

      }
    }

  };

  const editUserPointsOnSignUp = async (event) => {

    let newValue = window.prompt("Please enter the new value:");

    if (newValue === null || newValue === "" || isNaN(newValue)) {

      console.log("No value entered.");
      return

    } else {

      try {

        const response = await axios.post("/api/shops/edit-user-points-on-signup", { newValue: newValue }, {
          headers: {
            'X-CSRF-Token': csrfToken
          }
        });

        setuserPointsOnSignUp(newValue);

      } catch (err) {

        console.log(err)

      }
    }

  };

  const deleteReward = (id) => {
    axios.delete(`/api/shops/delete-reward/${id}`, {
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => setRewards(response.data))
      .catch(error => console.error('Error:', error));
  };

  const handleScan = data => {

    if (data) {
      setIsScanning(false);
      axios.put(`/api/users/updatePoints`, { qrData: data }, {
        withCredentials: true,
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
        .then(response => setServerResponse(response.data))
        .catch(error => {
          console.error('Error:', error);
          setServerResponse(error.message);
        });
    }
  }

  const fetchMetrics = () => {
    axios.get(`/api/shops/metrics`, {
      withCredentials: true,
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => setMetrics(response.data))
      .catch(error => console.error('Error:', error));
  }

  const renderServerResponse = () => {
    if (typeof serverResponse === 'string') {
      return serverResponse;
    } else if (serverResponse && (serverResponse.message || serverResponse.error)) {
      return serverResponse.message || serverResponse.error;
    }
    return '';
  }

  const logout = () => {
    axios.post(`/api/users/logout`, {}, {
      withCredentials: true,
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        setTimeout(() => {
          setUser(null);
          navigate("/");
        }, 500);
      })
      .catch(error => console.error('Error:', error));
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img src={logo} alt="Logo" style={{ width: '80px', height: '80px' }} />
          </Typography>
          <Button color="inherit" onClick={logout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" style={{ fontWeight: 'bold' }}>
            Welcome {user.firstname}
          </Typography>
          <Typography component="h2" variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Shop ID: {shopPublicID}
          </Typography>
          <Typography component="h2" variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Your users earn {shopPointsValue} points on each scan.
          </Typography>
          <Typography component="h2" variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Your users earn {userPointsOnSignUp} points on sign up.
          </Typography>
          {/* <Typography component="h1" variant="h5" style={{ fontWeight: 'bold' }}>
            You have 10 users.
          </Typography> */}
          <div className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                  Available Rewards
                </Typography>
                <List>
                  {rewards.map(reward => (
                    <ListItem key={reward._id}>
                      {/* <ListItemText primary={reward.name} secondary={`Points: ${reward.pointsRequired}`} /> */}
                      <ListItemText>
                        <Typography variant="subtitle1">{reward.name}</Typography>
                        <Typography variant="body2" color="textSecondary" style={{ wordWrap: 'break-word', maxWidth: '20ch' }}>
                          {`Description: ${reward.description}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">{`Points: ${reward.pointsRequired}`}</Typography>
                      </ListItemText>
                      <Button variant="contained" style={{ backgroundColor: '#1976d2', color: '#FFFFFF' }} color="primary" onClick={() => deleteReward(reward._id)}>
                        Delete Reward
                      </Button>
                    </ListItem>
                  ))}
                </List>

                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                    Add New Reward
                  </Typography>
                  <Grid container spacing={2} direction="column" alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="rewardName"
                        required
                        fullWidth
                        id="rewardName"
                        label="Reward Title"
                        value={newRewardName}
                        onChange={(e) => { setNewRewardName(e.target.value); setErrors({}); }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="rewardDescription"
                        required
                        fullWidth
                        id="rewardDescription"
                        label="Description"
                        value={newRewardDescription}
                        onChange={(e) => { setNewRewardDescription(e.target.value); setErrors({}); }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="rewardPointsRequired"
                        required
                        fullWidth
                        id="rewardPointsRequired"
                        label="Points Required"
                        value={newRewardPointsRequired}
                        onChange={(e) => { setNewRewardPointsRequired(e.target.value); setErrors({}); }}
                      />
                      {Object.keys(errors).map((key, index) => (
                        <Typography key={index} variant="body2" color="error">
                          {errors[key]}
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      marginTop: '1em',
                      backgroundColor: '#1976d2',
                      color: '#FFFFFF'
                    }}
                    onClick={addReward}
                  >
                    Add Reward
                  </Button>
                </Box>
                <Grid item xs={12} align="center">
                  {isScanning && (
                    <QrReader
                      onResult={handleScan}
                      scanDelay={300}
                      constraints={{
                        facingMode: 'environment',
                        width: { min: 1000 },
                        height: { min: 1000 }
                      }}
                    />
                  )}
                  <p>{renderServerResponse()}</p>
                  {!isScanning && (
                    // <Button variant="contained" style={{ backgroundColor: '#1976d2', color: '#FFFFFF' }} color="primary" onClick={() => setIsScanning(true)}>
                    //   Scan Customer's QR Code
                    // </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#1976d2', color: '#FFFFFF' }}
                      color="primary"
                      onClick={() => {
                        setServerResponse('');
                        setIsScanning(true)
                      }
                      }
                      fullWidth
                    >
                      Scan Customer's QR Code
                    </Button>
                  )}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      marginTop: '1em',
                      backgroundColor: '#1976d2',
                      color: '#FFFFFF'
                    }}
                    onClick={editShopPointsValue}
                  >
                    Edit Points Users Earn on Scan
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{
                      marginTop: '1em',
                      backgroundColor: '#1976d2',
                      color: '#FFFFFF'
                    }}
                    onClick={editUserPointsOnSignUp}
                  >
                    Edit Points Users Earn on Sign Up
                  </Button>
                </Grid>
                {/* <Grid item xs={12} align="center"> */}
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#1976d2', color: '#FFFFFF' }}
                    color="primary"
                    onClick={() => fetchMetrics()}
                    fullWidth
                  >
                    View Analytics
                  </Button>
                  {metrics && <MetricsChart metrics={metrics} />}
                </Box>
                {/* </Grid> */}

              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );

}

export default ShopDashboard;