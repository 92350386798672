// client/src/Dashboard.js
import React, { useContext, useState, useEffect } from "react";
import QRCode from "qrcode.react";
import UserContext from "./UserContext";
import axios from "axios";
import io from 'socket.io-client';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, List, ListItem, ListItemText, Grid, makeStyles, Chip, Box, AppBar, Toolbar, Card, CardContent } from '@material-ui/core';

import logo from './bigrewards-logo.png';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#e8f7fc',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pointsCard: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: '#1976d2',
    color: '#fff',
  },
  title: {
    flexGrow: 1,
  },
  points: {
    // fontFamily: "'Fredericka the Great', cursive",
    fontWeight: 'bold',
    color: '#FFFFFF',
    // textShadow: '2px 2px #FF0000',
  },
  appBar: {
    backgroundColor: '#2F97C1',
  }
}));

function Dashboard() {
  const classes = useStyles();
  const { setUser, user } = useContext(UserContext);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [userStats, setUserStats] = useState({ streaks: { currentWeek: [], longest: 0 }, points: 0 });
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const navigate = useNavigate();

  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  useEffect(() => {
    if (user) {

      // If the user is a shop owner, redirect them to the shop dashboard
      if (user.shopOwner) {
        navigate('/shop-dashboard');
      }

      setQrCodeValue(`${user.firstname}-${user.email}`);

      // Fetch user stats from server
      axios.get(`/api/users/stats`, {
        withCredentials: true,
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
        .then(response => setUserStats(response.data))
        .catch(error => console.error('Error:', error));

      // Fetch rewards from server
      axios.get(`/api/shops/user-rewards`, {
        withCredentials: true,
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
        .then(response => setRewards(response.data))
        .catch(error => console.error('Error:', error));

      const socket = io('/');

      // Emit 'userConnected' event with user email
      socket.emit('userConnected', user.email);

      // Emit 'userActive' event whenever the user interacts with the page, throttled to once every 5 minutes.
      const emitUserActive = _.throttle(() => socket.emit('userActive', user.email), 5 * 60 * 1000);
      window.addEventListener('mousemove', emitUserActive);
      window.addEventListener('keydown', emitUserActive);
      window.addEventListener('click', emitUserActive);
      window.addEventListener('touchstart', emitUserActive);
      window.addEventListener('touchmove', emitUserActive);
      window.addEventListener('touchend', emitUserActive);

      socket.on('pointsUpdated', () => {
        // Refresh dashboard
        fetchUserStats();
      });

      socket.on('disconnect', () => {
        alert("Your session has been refreshed to ensure optimal performance.");
        window.location.reload();
      });

      // Disconnect when the component is unmounted
      return () => {
        socket.disconnect();
        window.removeEventListener('mousemove', emitUserActive);
        window.removeEventListener('keydown', emitUserActive);
        window.removeEventListener('click', emitUserActive);
        window.removeEventListener('touchstart', emitUserActive);
        window.removeEventListener('touchmove', emitUserActive);
        window.removeEventListener('touchend', emitUserActive);
      };

    } else {

      navigate('/login');

    }

  }, []);

  const fetchUserStats = () => {
    axios.get(`/api/users/stats`, {
      withCredentials: true,
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => setUserStats(response.data))
      .catch(error => console.error('Error:', error));
  }

  const redeemReward = (rewardId, rewardName) => {
    axios.post(`/api/shops/redeem-reward/${rewardId}`, {}, {
      withCredentials: true,
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        if (window.confirm(`${rewardName} redeemed! Show this notification before pressing OK to refresh the page.`)) {
          fetchUserStats();
        }
      })
      // .catch(error => console.error('Error:', error));
      .catch(error => {
        console.error('Error:', error);
        alert('You do not have enough points to redeem this reward.');
      });
  }

  const logout = () => {
    axios.post(`/api/users/logout`, {}, {
      withCredentials: true,
      headers: {
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        setTimeout(() => {
          setUser(null);
          navigate("/");
        }, 500);
      })
      .catch(error => console.error('Error:', error));
  }


  if (!user) {
    return <div> Loading...</div>
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img src={logo} alt="Logo" style={{ width: '80px', height: '80px' }} />
          </Typography>
          <Button color="inherit" onClick={logout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Typography component="h1" variant="h5" style={{ fontWeight: 'bold' }}>
            Welcome {user.firstname}
          </Typography>
          <Card className={classes.pointsCard}>
            <CardContent>
              <Typography component="h1" variant="h2" className={classes.points}>
                {/* {userStats.points} Points */}
                {userStats.points} {userStats.points === 1 ? 'Point' : 'Points'}
              </Typography>
            </CardContent>
          </Card>
          <div className={classes.form}>
            <Grid container spacing={2}>

              {/* <Grid item xs={12}>
                <Box display="flex" justifyContent="center" flexWrap="wrap">
                  {userStats.streaks.currentWeek.map((streak, index) => (
                    <Chip
                      label={daysOfWeek[index]}
                      style={{ backgroundColor: streak ? '#4caf50' : '#f73434', color: 'white', marginRight: '5px' }}
                      key={index}
                    />
                  ))}
                </Box>
              </Grid> */}
              {/* <Grid item xs={12} align="center">
                <Typography>
                  Longest Streak: {userStats.streaks.longest}
                </Typography>
              </Grid> */}

              <Grid item xs={12} align="center">
                {qrCodeValue && <QRCode value={qrCodeValue} size={300} bgColor={"transparent"} />}
              </Grid>
              <Grid item xs={12}>
              <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                Available Rewards
              </Typography>
              <List>
                {rewards.length > 0 ? (
                  rewards.map(reward => (
                    <ListItem key={reward._id}>
                      <ListItemText primary={<span style={{ fontWeight: 'bold' }}>{reward.name}</span>} secondary={`Points: ${reward.pointsRequired}`} />
                      <Button variant="contained" style={{ backgroundColor: '#1976d2', color: '#FFFFFF' }} color="primary" onClick={() => redeemReward(reward._id, reward.name)}>
                        Redeem
                      </Button>
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                    No rewards
                  </Typography>
                )}
              </List>
            </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Dashboard;