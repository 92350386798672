import { useContext } from "react";
import { Navigate, Outlet } from 'react-router-dom';
import UserContext from "./UserContext";

const AuthLayout = () => {

  const { user } = useContext(UserContext);

  if (!user) {
    return <Navigate to={"/login"} replace />;
  }

  return <Outlet />;
 
};

export default AuthLayout;